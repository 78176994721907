/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/image';
@import '../../../style/abstract/parts';

.vat_id_country_wrapper {
  position: relative;

  .vat_id_country_label {
    position: absolute;
    z-index: 1;
    background: var(--input-border-color);
    height: calc(100% - 2px);
    top: 1px;
    left: 1px;
    line-height: 3;
    padding: 0 7px;
  }

  #vat_id {
    padding-left: 35px;

    &::placeholder {
      font-size: 12px;
    }
  }
}