/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../pwasaas/src/style/abstract/variables';
@import '../../../../../pwasaas/src/style/abstract/media';
@import '../../../../../pwasaas/src/style/abstract/button';
@import '../../../../../pwasaas/src/style/abstract/loader';
@import '../../../../../pwasaas/src/style/abstract/icons';
@import '../../../../../pwasaas/src/style/abstract/image';
@import '../../../../../pwasaas/src/style/abstract/parts';

:root {
    --header-menu-main-item-margin-right-after-tablet: 0;

    --saas-header-usps-background: #F9F9F9;
    --saas-header-usps-height: 70px;
    --saas-header-usps-padding-y: 0px;

    @include mobile {
        --saas-header-usps-total-height: 0px;
    }

    @include before-wide-desktop {
        --saas-header-usps-height: 60px;
    }

    @include tablet {
        --saas-header-usps-height: 50px;
    }
}

.SaasHeader {
    --saas-header-nav-menu-padding-y: 0px;
    --saas-header-menu-bottom-background: #45BAC1;

    &-LogoWrapper {
        @include mobile {
            max-width: 15rem;
        }
    }

    &-Usps {
        .ContentWrapper {
            align-items: center;
            display: flex;
        }

        .SiteUsp {
            &-Content {
                font-size: 1.6rem;
                color: #000;
                font-style: italic;

                @include tablet {
                    font-size: 1.4rem;
                }

                @include narrow-desktop {
                    font-size: 1.5rem;
                }

                @include before-wide-desktop {
                    font-size: 1.6rem;
                }
            }

            &-Icon {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 0.2rem solid #45BAC1;
                border-radius: 50%;
                color: #E41B68;
                width: 3.6rem;
                height: 3.6rem;

                @include tablet {
                    width: 2.6rem;
                    height: 2.6rem;
                    font-size: 1.4rem;
                }
            }
        }
    }
}
